// Lib
import { padStart } from 'lodash';

// Type
import { DebitCardV2Props } from './DebitCardV2.type';
// import {
//   DebitCardType,
//   DebitCardIssuerType,
// } from '../../../../types/DebitCard.type';
import { OneDigitStringType } from '../../../../types/General.type';
// import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
// import { SavingsAccountType } from '../../../../types/SavingsAccount.type';

class DebitCardV2 {
  private id: string;

  // protected savingsAccountNumber: string;

  // protected userId: string;

  // protected annualFeeNextPaymentDate?: string;

  // protected balance: number;

  // protected cardHolder: string;

  // protected currency: FiatCurrencyType;

  // protected isDeleted: boolean;

  // protected last4digits: string;

  // protected type: DebitCardType;

  // protected issuer: DebitCardIssuerType;

  // protected savingsAccountType: SavingsAccountType;

  // protected topUpLimit: number;

  // protected pendingBalance: number;

  // protected validThrough: {
  //   month: number;
  //   year: number;
  // };

  protected createdAt: number;

  protected updatedAt: number;

  protected props: DebitCardV2Props;

  constructor(id: string, props: DebitCardV2Props) {
    this.id = id;
    // this.savingsAccountNumber = props.savingsAccountNumber;
    // this.userId = props.userId;
    // this.annualFeeNextPaymentDate = props.annualFeeNextPaymentDate;
    // this.balance = props.balance;
    // this.cardHolder = props.cardHolder;
    // this.currency = props.currency;
    // this.isDeleted = props.isDeleted;
    // this.issuer = props.issuer ?? DebitCardIssuerType.cherryVisa01;
    // this.last4digits = props.last4digits;
    // this.pendingBalance = props.pendingBalance;
    // this.savingsAccountType = props.savingsAccountType;
    // this.topUpLimit = props.topUpLimit;
    // this.type = props.type;
    // this.validThrough = props.validThrough;
    this.createdAt = props.createdAt || Date.now();
    this.updatedAt = props.updatedAt || Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getFacedCardNumber = () => `************${this.props.last4digits}`;

  public getFormattedFacedNumber = () =>
    `****-****-****-${this.props.last4digits}`;

  public getValidThrough4Digits = () => {
    const { year, month } = this.props.validThrough;

    const monthZeroPadded = padStart(String(month), 2, '0');
    const yearLast2Digits = String(year).slice(-2);

    return {
      month01: monthZeroPadded.slice(0, 1) as OneDigitStringType,
      month02: monthZeroPadded.slice(-1) as OneDigitStringType,
      year01: yearLast2Digits.slice(0, 1) as OneDigitStringType,
      year02: yearLast2Digits.slice(-1) as OneDigitStringType,
    };
  };
}
export default DebitCardV2;
