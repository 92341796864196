import Joi from '@hapi/joi';

// Type
import {
  DebitCardType,
  DebitCardIssuerType,
} from '../../../../types/DebitCard.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { SavingsAccountType } from '../../../../types/SavingsAccount.type';

// Constant
import { DATE_FORMAT_SLASH } from '../../../../config/constants/regularExpression';
import { MAXIMUM_TOP_UP_AMOUNT } from '../../../../config/constants/business';

export interface DebitCardV2Props {
  userId: string;

  savingsAccountNumber: string;

  annualFeeNextPaymentDate?: string;

  balance: number;

  cardHolder: string;

  currency: FiatCurrencyType;

  isDeleted: boolean;

  issuer?: DebitCardIssuerType;

  last4digits: string;

  pendingBalance: number;

  savingsAccountType: SavingsAccountType;

  topUpLimit: number;

  type: DebitCardType;

  validThrough: {
    month: number;
    year: number;
  };

  is3DSecure: boolean;

  canTopUp: boolean;

  createdAt?: number;

  updatedAt?: number;
}

export interface DebitCardV2RawDataType {
  id: string;

  props: DebitCardV2Props;
}

export const DebitCardV2PropsFormat = {
  id: Joi.string(),

  savingsAccountNumber: Joi.string().required(),

  userId: Joi.string().required(),

  annualFeeNextPaymentDate: Joi.string().pattern(DATE_FORMAT_SLASH).empty(''),

  balance: Joi.number().required(),

  cardHolder: Joi.string().required(),

  currency: Object.keys(FiatCurrencyType)
    .reduce((joi, currency) => joi.valid(currency), Joi.string())
    .required(),

  isDeleted: Joi.boolean().required(),

  issuer: Object.keys(DebitCardIssuerType).reduce(
    (joi, issuer) => joi.valid(issuer),
    Joi.string(),
  ),

  last4digits: Joi.string().length(4).pattern(/[0-9]/).required(),

  pendingBalance: Joi.number().required(),

  savingsAccountType: Object.keys(SavingsAccountType)
    .reduce((joi, type) => joi.valid(type), Joi.string())
    .required(),

  topUpLimit: Joi.number().min(0).max(MAXIMUM_TOP_UP_AMOUNT).required(),

  type: Object.entries(DebitCardType)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .reduce((joi, [key, value]) => joi.valid(value), Joi.string())
    .required(),

  validThrough: {
    month: Joi.number().min(1).max(12).required(),
    year: Joi.number().min(2021),
  },

  is3DSecure: Joi.boolean().required(),

  canTopUp: Joi.boolean().required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};
